const schema = {
  endpoint: '/api/contest_edition_submissions',
  access: 'contestEditionSubmission',
  resource: {
    definition: '#ContestEditionSubmission',
    properties: {
      fullName: {
        description: 'Name and surname',
        fullWidth: true,
        validate: ['required'],
      },
      email: {
        description: 'T_GENERAL_EMAIL',
        fullWidth: true,
        validate: ['required'],
      },
      nomineeFullName: {
        fullWidth: true,
        validate: ['required'],
      },
      ilustratorFullName: {
        fullWidth: true,
        label: 'Imię i nazwisko ilustratora(ki)',
      },
      bookTitle: {
        fullWidth: true,
        validate: ['required'],
        label: 'T_MODULE_CONTEST_EDITION_SUBMISSION_BOOK_TITLE',
      },
      publisher: {
        fullWidth: true,
        validate: ['required'],
        label: 'T_MODULE_CONTEST_EDITION_SUBMISSION_PUBLISHER',
      },
      publishYear: {
        fullWidth: true,
        validate: ['required'],
        label: 'Rok Wydania',
      },
      notes: {
        type: 'textarea',
        fullWidth: true,
      },
      consent1: {
        type: 'boolean',
        validate: ['required'],
        label: 'Zgoda 1',
      },
      consent2: {
        type: 'boolean',
        label: 'Zgoda 2',
      },
      consent3: {
        type: 'boolean',
        validate: ['required'],
        label: 'Zgoda 3',
      },
    },
  },
}

export default schema
