import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CloudUploadOutlined } from '@material-ui/icons'
import { Button, CircularProgress } from '@material-ui/core'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'

export const ImportModal = ({ setReloadCollection }) => {
  const [isFetching, setIsFetching] = useState(false)

  const handleFileChange = event => {
    handleUpload(event.target.files[0])
  }

  const handleUpload = async file => {
    if (!file) {
      notification('error', 'Nie wybrano pliku')
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    const handleResponse = jsonData => {
      setReloadCollection(true)
      if (jsonData.error) {
        notification('error', jsonData.error)
      }

      if (jsonData.message) {
        notification('success', jsonData.message)
      }
    }

    try {
      setIsFetching(true)
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENTRYPOINT}/api/survey_import`,
        {
          method: 'POST',
          headers: {
            ...authHeader(),
          },
          body: formData,
        }
      )

      const result = await response.json()
      setIsFetching(false)
      handleResponse(result)
    } catch (error) {
      notification('error', 'Błąd podczas przesyłania pliku')
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <input
        accept=".xls,application/vnd.ms-excel"
        id="file-upload"
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={isFetching}
        >
          <CloudUploadOutlined></CloudUploadOutlined>
          <span style={{ paddingLeft: '5px' }}>Importuj ankietę</span>
        </Button>
      </label>
      {isFetching && <CircularProgress size={24} />}
    </div>
  )
}

ImportModal.propTypes = {
  setReloadCollection: PropTypes.func.isRequired,
}
